<template>
<section class="content-wrapper">
    <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <img src="../assets/images/logo-beekeeper.png">
                            </div>
                            <h4 class="text-center mt-4">Un code a été envoyé à votre adresse mail!</h4>
                            <h6 class="font-weight-light text-center">Entrer le code envoyé</h6>
                            <form class="pt-3" @submit.prevent="handleSubmit">
                            <div class="form-group">
                                <input type="text" v-model="key" class="form-control form-control-lg" id="key" placeholder="Entrer le code envoyé">
                            </div>
                            
                            <div class="mt-3">
                                <button class="btn btn-block  btn-lg font-weight-medium auth-form-btn" style="background:#0055a4; color:white">Confirmer</button>
                            </div>
                            <div class="text-center mt-4 font-weight-light">
                                Vous n'avez pas reçu le code? <router-link :to="{name: 'forgotPassword'}" class="text-primary">Renvoyer</router-link>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
const axios = require('axios').default
export default {
    name:'enterKey',
    data () {
        return {
            key:''
        }
    },
    methods: {
          async handleSubmit () {
              localStorage.setItem('token', this.key)

              // vérification token
              axios.get('myinfos',{
                  headers: {
                      'X-AUTH-TOKEN': localStorage.getItem('token')
                }
                })
                .then(()=> {
                    this.$snotify.success('Code confirmé', {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    });
                    this.$router.push ({
                        name: 'changePassword'
                        })
                    })
        .catch(()=> alert('Le code que vous avez entré n`est pas correct'))
    }
    }
}
</script>